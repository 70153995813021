import { atom } from "recoil";
import { DialogContext } from "../dialog/DialogContext";

export interface StageDefinition {
  id: string;
  locked: boolean;
  completed: boolean;
  currentStep: number;
}

export interface GameState {
  dialogContext: DialogContext;
  currentStage: string;
  stages: StageDefinition[];
  dialogLog: Record<string, string[]>; // key = dialog id, value = list of prompts chosen
}

export const initialStages: StageDefinition[] = [
  {
    id: "core1",
    locked: false,
    completed: false,
    currentStep: 0,
  },
  {
    id: "core2",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "core3",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "core4",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "core5",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "core6",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "elective1",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "elective2",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "elective3",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "elective4",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "elective5",
    locked: true,
    completed: false,
    currentStep: 0,
  },
  {
    id: "elective6",
    locked: true,
    completed: false,
    currentStep: 0,
  },
];

export interface GameStep {
  id: string;
  label: string;
  component: (
    onComplete: (context?: DialogContext) => void,
    dialogContext: DialogContext
  ) => React.ReactNode;
}

export interface GameStage {
  id: string;
  label: string;
  steps: GameStep[];
}

export const initialGameState: GameState = {
  currentStage: "core1",
  dialogContext: {
    DialogFlags: {},
    Flags: {},
    insight: 0,
    trust: 0,
  },
  dialogLog: {},
  stages: initialStages,
};

export const gameStateAtom = atom<GameState>({
  key: "gameState",
  default: initialGameState,
});
