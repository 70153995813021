import styled from "@emotion/styled";
import * as DialogTypes from "./Dialog";
import { SpeakerTail } from "./SpeakerTail";
import { DialogMenuButton } from "./DialogMenuButton";
import { DialogPatientChart } from "./DialogPatientChart";
import { DialogTimeElapsed } from "./DialogTimeElapsed";
import { ReactNode } from "react";
import { PromptFrame } from "./PromptFrame";
import { ResponseBox } from "./ResponseBox";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  emrInteractiveAtom,
  emrLastUpdateAtom,
  emrUpdateAtom,
} from "../state/emrAtom";
import { CategoryResponses } from "./useDialog";
import { CategoryResponsesPrompt } from "./CategoryResponsesPrompt";
import { InsightDisplay } from "./InsightDisplay";
import { TranscriptButton } from "./TranscriptButton";
import { useToggle } from "../util/useToggle";
import { EMRBanner } from "./EMRBanner";
import { DialogResponses } from "./DialogResponses";

const PromptQuote = styled.div`
  color: #fff;
  font-family: Noto Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  position: absolute;
  top: 10px;
  left: 20px;
`;

const PromptBubble = styled.div`
  position: relative;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border-radius: 32px;
  background: rgba(0, 23, 31, 0.5);
  padding: 10px 10px 10px 60px;
  margin: 16px;
`;

const ResponseLabel = styled.div`
  color: #fff;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 30px 16px 16px 16px;
  padding-left: 20px;
  width: 100%;
`;

const TailPosition = styled.div`
  position: absolute;
  bottom: -29px;
  right: 40px;
`;

const BottomBarRow = styled.div`
  label: BottomBarRow;
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  flex-shrink: 0;
  width: 100%;
  height: 82px;
  gap: 1px;
`;

const BottomBar = styled.div`
  label: BottomBar;
  width: 100%;
  position: absolute;
  bottom: -18px;
  margin-top: 5px;
  padding: 0px;
  box-sizing: border-box;
  > svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const BannerContainer = styled.div`
  width: 100%;
  height: 40px;
`;

interface DialogPromptProps {
  prompt: DialogTypes.DialogPrompt;
  generalResponses: DialogTypes.DialogResponse[];
  categoryResponses: CategoryResponses;
  open: boolean;
  elapsedTime: number;
  showOptions: boolean;
  onResponse: (response: DialogTypes.DialogResponse, idx: number) => void;
  children?: ReactNode;
  toggleTranscript?: () => void;
}

const getEmrTab = (
  lastEmrUpdate: string | null
): "snapshot" | "sdoh" | "history" | "acp" | "4ms" => {
  if (!lastEmrUpdate) return "snapshot";
  if (lastEmrUpdate.startsWith("sdoh")) return "sdoh";
  if (lastEmrUpdate.startsWith("history")) return "history";
  if (lastEmrUpdate.startsWith("acp")) return "acp";
  if (lastEmrUpdate.startsWith("fourM")) return "4ms";
  return "snapshot";
};

export const DialogPrompt: React.FC<DialogPromptProps> = ({
  prompt,
  generalResponses,
  categoryResponses,
  open,
  onResponse,
  elapsedTime,
  showOptions,
  children,
  toggleTranscript,
}) => {
  const [emrUpdate, setEmrUpdate] = useRecoilState(emrUpdateAtom);
  const [emrInteractive] = useRecoilState(emrInteractiveAtom);
  const lastEmrUpdate = useRecoilValue(emrLastUpdateAtom);

  return (
    <>
      {children}

      <PromptBubble id="promptBubble">
        <PromptQuote>“</PromptQuote>
        {prompt.txt}
        <TailPosition>
          <SpeakerTail />
        </TailPosition>
      </PromptBubble>

      <DialogResponses
        generalResponses={generalResponses}
        categoryResponses={categoryResponses}
        onResponse={onResponse}
        showOptions={showOptions}
        elapsedTime={elapsedTime}
        emrUpdate={emrUpdate}
        showResponses={open}
        setEmrUpdate={setEmrUpdate}
        emrInteractive={emrInteractive}
        lastEmrUpdate={lastEmrUpdate}
        toggleTranscript={toggleTranscript}
      />
    </>
  );
};
