import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogResponse } from "./Dialog";

import { useDialog } from "./useDialog";
import styled from "@emotion/styled";

import { DialogContext, initDialogContext } from "./DialogContext";
import { DialogLog } from "./DialogLog";
import { Slide } from "./Slide";
import { DialogPrompt } from "./DialogPrompt";
import { DialogEMR } from "./DialogEMR";
import { Assessment } from "./Assessment";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
//import { InsightDisplay } from "./InsightDisplay";
import { NPCVideo } from "./NPCVideo";
import { PromptFrame } from "./PromptFrame";
import { useLocalStorage, useToggle } from "usehooks-ts";
import { DialogTranscript } from "./DialogTranscript";
import { useRecoilState } from "recoil";
import { npcNameAtom, npcTitleAtom } from "./dialog-state";
import { emrVisibleAtom } from "../state/emrAtom";
//import { emrAtom, emrVisibleAtom } from "../state/emrAtom";
//import { insightStateAtom } from "../state/insight";

const NPCTitle = styled.div`
  position: absolute;
  left: 549px;
  bottom: 40px;
  color: #fff;

  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.85);
`;
const NPCInfo = styled.div`
  position: absolute;
  left: 549px;
  bottom: 20px;
  color: #fff;

  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.85);
`;

const Fullscreen = styled.div({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  alignItems: "center",
  zIndex: 1000,
});

//const nurseIdleOverride = "/dialog_videos/nurse/nurse_idle.mp4";

export const DialogPlayer: React.FC<{
  dialog: Dialog;
  showLog?: boolean;
  onCompleted?: (context: DialogContext, dialogLog: string[]) => void;
  initialContext?: DialogContext;
  showPromptButtons: boolean;
  skipAllVoiceovers?: boolean;
  idleSrc: string;
  show4MEmrTab?: boolean;
}> = ({
  dialog,
  showLog,
  onCompleted,
  initialContext,
  skipAllVoiceovers,
  showPromptButtons,
  idleSrc,
  show4MEmrTab,
}) => {
  const [mode, setMode] = useState("cmo");

  const [dialogLog, setDialogLog] = useState<string[]>([]);

  const [npcName, setNpcName] = useRecoilState(npcNameAtom);
  const [npcTitle, setNpcTitle] = useRecoilState(npcTitleAtom);
  const [emr] = useRecoilState(emrVisibleAtom);
  const [doNotWaitForVideo, setDoNotWaitForVideo] = useLocalStorage(
    "doNotWaitForVideo",
    false
  );

  //const [insight] = useRecoilState(insightStateAtom);

  const onDialogComplete = useCallback(
    (context: DialogContext) => onCompleted && onCompleted(context, dialogLog),
    [dialogLog, onCompleted]
  );

  const [transcriptOpen, toggleTranscript] = useToggle(false);

  const [logVisible, toggleLogVisible] = useToggle(showLog);

  useEffect(() => {
    window.setMode = (mode: string) => {
      setMode(mode);
    };
    window.setNPCName = (name: string, title?: string) => {
      setNpcName(name);
      title && setNpcTitle(title);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && event.key === "k") {
        toggleLogVisible();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setNpcName, setNpcTitle, toggleLogVisible]);

  const {
    history,
    prompt,
    generalResponses,
    categoryResponses,
    video,
    dialogCompleted,
    selectResponse,
    context,
    elapsedTime,
    promptOpen,
    addAssessmentTime,
    onVideoEnded,
    secondaryVideo,
    currentNodeId,
    goToNode,
    onVideoError,
  } = useDialog(
    dialog,
    initialContext || initDialogContext(dialog, 0, 0),
    onDialogComplete,
    "/dialog_vo/ceo",
    !!skipAllVoiceovers,
    14,
    doNotWaitForVideo
  );

  const onResponse = (response: DialogResponse) => {
    setDialogLog((dialogLog) => [...dialogLog, response.txt]);
    selectResponse(response);
  };
  console.info(dialogLog);

  const effectiveIdleSrc = idleSrc;

  return (
    <Fullscreen>
      <Slide />

      <DialogEMR onClose={goToNode} show4MEmrTab={show4MEmrTab} />

      <NPCVideo
        key="primaryVideo"
        src={video && `/dialog_videos/${video}`}
        idleSrc={effectiveIdleSrc}
        thumbnail={
          !!emr || (!!prompt.action && prompt.action.includes("showSlide"))
        }
        visible={!dialogCompleted}
        onEnded={onVideoEnded}
        onError={onVideoError}
      />

      <NPCVideo
        key="secondaryVideo"
        src={secondaryVideo && `/dialog_videos/${secondaryVideo}`}
        idleSrc={effectiveIdleSrc}
        thumbnail={true}
        visible={!!secondaryVideo && !dialogCompleted}
        onEnded={onVideoEnded}
        onError={onVideoError}
      />

      {currentNodeId === "nurse" && (
        <NPCVideo
          key="nurseVideo"
          src={`/dialog_videos/nurse/nurse_line.mp4`}
          idleSrc={`/dialog_videos/nurse/nurse_idle.mp4`}
          thumbnail={true}
          visible={!dialogCompleted}
          onEnded={onVideoEnded}
          onError={onVideoError}
        />
      )}

      <PromptFrame open={true || promptOpen}>
        {transcriptOpen || (
          <Assessment
            dialog={dialog}
            onResponse={onResponse}
            prompt={prompt}
            responses={generalResponses}
            onAssessmentComplete={addAssessmentTime}
          >
            <DialogPrompt
              prompt={prompt}
              generalResponses={generalResponses}
              categoryResponses={categoryResponses}
              open={promptOpen}
              elapsedTime={elapsedTime}
              onResponse={onResponse}
              showOptions={showPromptButtons && mode === "patient"}
              toggleTranscript={toggleTranscript}
            />
          </Assessment>
        )}

        {transcriptOpen && history && (
          <DialogTranscript
            history={history}
            toggleTranscript={toggleTranscript}
            currentPrompt={prompt.txt}
          />
        )}
      </PromptFrame>

      <NPCTitle>{npcName}</NPCTitle>
      <NPCInfo>{npcTitle}</NPCInfo>

      {logVisible && history && (
        <DialogLog
          history={history}
          context={context}
          toggleWaitForVideo={setDoNotWaitForVideo}
          doNotWaitForVideo={doNotWaitForVideo}
        />
      )}

      <ToastContainer position="bottom-center" />
    </Fullscreen>
  );
};

DialogPlayer.defaultProps = {
  showLog: false,
};

declare global {
  interface Window {
    setMode: (mode: string) => void;
    setNPCName: (name: string, title?: string) => void;
  }
}
