import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import menuBackground from "./MenuBackground.svg";
import stripes from "./black-twill.png";
import CheckIcon from "./CheckIcon";
import LockIcon from "./LockIcon";
import LibraryIcon from "./LibraryIcon";
import LevelDetails from "./LevelDetails";
import OpenCloseIcon from "./OpenCloseIcon";
import { useRecoilState } from "recoil";
import { gameStateAtom } from "../../state/gameStateAtom";
import { useSavedGame } from "../../simulation/useSavedGames";
import { useNavigate } from "react-router-dom";
import geriactLogo from "./geriact_splash_screen.png";
import StartIcon from "./StartIcon";
import CheatCodePopup from "../CheatCodePopup";
import { gameStages } from "../GameStages";
import { saveGameState } from "../../state/saveGameService";
import HamburgerMenu from "../HamburgerMenu";
import { LoadingScreen } from "../LoadingScreen";

interface MenuItem {
  id: string;
  label: string;
  description: string;
  image: string;
  section: "core" | "elective";
  available: boolean;
}

const menuItems: MenuItem[] = [
  // Core items
  {
    id: "core1",
    description:
      "Frame each clinical encounter with older adults in a streamlined yet comprehensive framework that covers Geriatric issues not commonly addressed in usual health care.",
    label: "Introduction to the 4Ms",
    image: "/menu_images/introductiontothe4ms.jpg",
    section: "core",
    available: true,
  },
  {
    id: "core2",
    description:
      "Create patient-centered health care by understanding what matters most to your older adult patients.",
    label: "Determining What Matters",
    image: "/menu_images/determiningwhatmatters.jpg",
    section: "core",
    available: true,
  },
  {
    id: "core3",
    description:
      "Identify age-dependent issues with cognition and behavior through evidence - based assessment tools and multimodal interventions.",
    label: "Cognitive and behavioral health",
    image: "/menu_images/cognitiveandbehavioralhealth.jpg",
    section: "core",
    available: false,
  },
  {
    id: "core4",
    description:
      "Recognize pre-frailty syndromes and frailty through structured assessments and management plans.",
    label: "Managing frailty",
    image: "/menu_images/managingfrailty.jpg",
    section: "core",
    available: false,
  },
  {
    id: "core5",
    description:
      "One third of the aging population has 3 or more chronic conditions thus requiring knowledge and skills to evaluate and manage progressive conditions along with Geriatric syndromes.",
    label: "Multi complexity",
    image: "/menu_images/multicomplexity.jpg",
    section: "core",
    available: false,
  },
  {
    id: "core6",
    description:
      "Utilize data and metrics to help standardize processes and improve outcomes such as fall reduction or use of fewer unsafe medications.",
    label: "Population health",
    image: "/menu_images/populationhealth.jpg",
    section: "core",
    available: false,
  },
  // Elective items
  {
    id: "elective1",
    label: "Social determinants of health",
    description:
      "Understand social factors that may influence health and pursue strategies to mitigate SDOH impact on healthy lifespans.",
    image: "/menu_images/socialdeterminantsofhealth.jpg",
    section: "elective",
    available: false,
  },
  {
    id: "elective2",
    label: "Team based care",
    description:
      "Garner interprofessional competencies to enhance collaborations, resources and support systems for patients.",
    image: "/menu_images/teambasedcare.jpg",
    section: "elective",
    available: false,
  },
  {
    id: "elective3",
    label: "The last two years of life",
    description:
      "Distinguish functional from absolute longevity to assist older adults transitioning to the final phase of their life.",
    image: "/menu_images/thelasttwoyearsoflife.jpg",
    section: "elective",
    available: false,
  },
  {
    id: "elective4",
    label: "Working with resistant patients",
    description:
      "Optimal population health requires provider's who are facile in dealing with a spectrum of patient attitudes towards prevention and adherence to medical plans and best practices.",
    image: "/menu_images/workingwithresistantpatients.jpg",
    section: "elective",
    available: false,
  },
  {
    id: "elective5",
    label: "Culturally sensitive care",
    description:
      "Practicing culturally sensitive health care improves patient trust, communication, and satisfaction while reducing health disparities.",
    image: "/menu_images/culturallysensitivecare.jpg",
    section: "elective",
    available: false,
  },
  {
    id: "elective6",
    label: "Health systems change",
    description: "Improve quality care while lowering costs.",
    image: "/menu_images/healthsystemschange.jpg",
    section: "elective",
    available: false,
  },
];

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(${menuBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const SplashImage = styled.img`
  width: 50%;
  max-width: 800px;
  height: auto;
  margin-bottom: 16px;
  margin-left: 40px;
`;

const MenuContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const MenuSection = styled.div`
  width: 480px;
  margin-right: 32px;
`;

const AccordionSection = styled.div`
  margin-bottom: 16px;
`;

const AccordionHeader = styled.button`
  width: 100%;
  text-align: left;
  padding: 16px;
  background-color: #012f50;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;

const AccordionContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background-color: #011e2d;
`;

const MenuItem = styled.button<{
  isLocked: boolean;
  isCompleted: boolean;
  isSelected: boolean;
}>`
  display: flex;
  width: 100%;
  color: ${(props) => (props.isSelected ? "#00171f" : "white")};
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  justify-content: space-between;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  background-color: ${(props) => (props.isSelected ? "#e8c547" : "#011e2d")};
  border: none;
  cursor: ${(props) => (props.isLocked ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isLocked ? 0.5 : 1)};
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const ItemIcon = styled.span`
  margin-right: 8px;
`;

const DetailSection = styled.div`
  min-height: 530px;
`;

const AccordionHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 16px;
`;

const GameMenuScreen: React.FC = () => {
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState<"core" | "elective">("core");
  const [gameState, setGameState] = useRecoilState(gameStateAtom);
  const [showCheatPopup, setShowCheatPopup] = useState(false);

  const isLocked = (item: MenuItem) => {
    return (
      !item.available ||
      gameState.stages.find((stage) => stage.id === item.id)?.locked ||
      false
    );
  };

  const isCompleted = (item: MenuItem) => {
    return (
      gameState.stages.find((stage) => stage.id === item.id)?.completed || false
    );
  };

  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(
    menuItems.find((item) => !isLocked(item) && !isCompleted(item)) ||
      menuItems[0]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && event.key === "l") {
        console.log("Unlocking all items");
        setGameState((state) => ({
          ...state,
          stages: state.stages.map((item) => ({
            ...item,
            locked: false,
          })),
        }));
      } else if (event.altKey && event.key === "k") {
        setShowCheatPopup((prev) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setGameState]);

  const handleItemClick = (item: MenuItem) => {
    setGameState((state) => ({
      ...state,
      currentStage: item.id,
    }));
    setSelectedItem(item);
  };

  const toggleSection = (section: "core" | "elective") => {
    setOpenSection(section);
  };

  const handleCheatStepSelect = (stageId: string, stepIndex: number) => {
    const newGameState = {
      ...gameState,
      currentStage: stageId,
      stages: gameState.stages.map((stage) =>
        stage.id === stageId
          ? { ...stage, locked: false, currentStep: stepIndex }
          : stage
      ),
    };
    saveGameState(newGameState).then(() => {
      setGameState(newGameState);
    });
    setShowCheatPopup(false);
    navigate("/play");
  };

  const icon = (item: MenuItem) =>
    isLocked(item) ? (
      <LockIcon />
    ) : item.id === selectedItem?.id ? (
      <StartIcon transform="scale(1.3)" onClick={onStart(item)} />
    ) : isCompleted(item) ? (
      <CheckIcon style={{ left: "5px", position: "relative" }} />
    ) : null;

  const onStart = (item: MenuItem) => () => {
    setGameState((state) => ({
      ...state,
      currentStage: item.id,
    }));
    setTimeout(() => {
      navigate("/play");
    });
  };

  const isOnFirstStepOfState = (item: MenuItem) => {
    return (
      gameState.stages.find((stage) => stage.id === item.id)?.currentStep === 0
    );
  };

  return (
    <>
      <BackgroundWrapper />
      <Container>
        <HamburgerMenu />
        <SplashImage src={geriactLogo} alt="GeriACT Splash Screen" />
        <MenuContainer>
          <MenuSection>
            <AccordionSection>
              <AccordionHeader onClick={() => toggleSection("core")}>
                <LibraryIcon />
                <AccordionHeaderContent>
                  <div>CORE</div>
                  <OpenCloseIcon open={openSection === "core"} />
                </AccordionHeaderContent>
              </AccordionHeader>
              <AccordionContent isOpen={openSection === "core"}>
                {menuItems
                  .filter((item) => item.section === "core")
                  .map((item, index) => (
                    <MenuItem
                      isSelected={item.id === selectedItem?.id}
                      key={item.id}
                      onClick={() => handleItemClick(item)}
                      isLocked={isLocked(item)}
                      isCompleted={isCompleted(item)}
                    >
                      {item.label}
                      <ItemIcon>{icon(item)}</ItemIcon>
                    </MenuItem>
                  ))}
              </AccordionContent>
            </AccordionSection>
            <AccordionSection>
              <AccordionHeader onClick={() => toggleSection("elective")}>
                <AccordionHeaderContent>
                  <div>ELECTIVES</div>
                  <OpenCloseIcon open={openSection === "elective"} />
                </AccordionHeaderContent>
              </AccordionHeader>
              <AccordionContent isOpen={openSection === "elective"}>
                {menuItems
                  .filter((item) => item.section === "elective")
                  .map((item, index) => (
                    <MenuItem
                      isSelected={item.id === selectedItem?.id}
                      key={item.id}
                      onClick={() => handleItemClick(item)}
                      isLocked={isLocked(item)}
                      isCompleted={isCompleted(item)}
                    >
                      {item.label}
                      <ItemIcon>{icon(item)}</ItemIcon>
                    </MenuItem>
                  ))}
              </AccordionContent>
            </AccordionSection>
          </MenuSection>

          <DetailSection>
            {selectedItem ? (
              <LevelDetails
                image={selectedItem.image}
                firstStep={isOnFirstStepOfState(selectedItem)}
                locked={isLocked(selectedItem)}
                title={selectedItem.label}
                description={selectedItem.description}
                onStart={onStart(selectedItem)}
              />
            ) : (
              <p>Select an activity to view details</p>
            )}
          </DetailSection>
        </MenuContainer>
        {showCheatPopup && (
          <CheatCodePopup
            onClose={() => setShowCheatPopup(false)}
            onStepSelect={handleCheatStepSelect}
            gameStages={gameStages}
            gameState={gameState}
          />
        )}
      </Container>
    </>
  );
};

const LoadingWrapper: React.FC = () => {
  const { loading } = useSavedGame();

  if (loading) {
    return <LoadingScreen label="Loading Game" />;
  }

  return <GameMenuScreen />;
};

export default LoadingWrapper;
