import React from "react";
import styled from "@emotion/styled";
import * as DialogTypes from "./Dialog";
import { ResponseBox } from "./ResponseBox";
import { CategoryResponsesPrompt } from "./CategoryResponsesPrompt";
import { DialogTimeElapsed } from "./DialogTimeElapsed";
import { InsightDisplay } from "./InsightDisplay";
import { EMRBanner } from "./EMRBanner";
import { DialogMenuButton } from "./DialogMenuButton";
import { DialogPatientChart } from "./DialogPatientChart";
import { TranscriptButton } from "./TranscriptButton";
import { CategoryResponses } from "./useDialog";

const ResponseLabel = styled.div`
  color: #fff;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  margin: 30px 16px 16px 16px;
  padding-left: 20px;
  width: 100%;
`;

const BottomBarRow = styled.div`
  label: BottomBarRow;
  display: flex;
  align-items: top;
  justify-content: space-evenly;
  flex-shrink: 0;
  width: 100%;
  height: 82px;
  gap: 1px;
`;

const BottomBar = styled.div`
  label: BottomBar;
  width: 100%;
  position: absolute;
  bottom: -18px;
  margin-top: 5px;
  padding: 0px;
  box-sizing: border-box;
  > svg {
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

const BannerContainer = styled.div`
  width: 100%;
  height: 40px;
`;

interface DialogResponsesProps {
  generalResponses: DialogTypes.DialogResponse[];
  categoryResponses: CategoryResponses;
  onResponse: (response: DialogTypes.DialogResponse, idx: number) => void;
  showOptions: boolean;
  elapsedTime: number;
  emrUpdate: string | null;
  setEmrUpdate: (update: string | null) => void;
  emrInteractive: boolean;
  lastEmrUpdate: string | null;
  toggleTranscript?: () => void;
  showResponses: boolean;
}

const getEmrTab = (
  lastEmrUpdate: string | null
): "snapshot" | "sdoh" | "history" | "acp" | "4ms" => {
  if (!lastEmrUpdate) return "snapshot";
  if (lastEmrUpdate.startsWith("sdoh")) return "sdoh";
  if (lastEmrUpdate.startsWith("history")) return "history";
  if (lastEmrUpdate.startsWith("acp")) return "acp";
  if (lastEmrUpdate.startsWith("fourM")) return "4ms";
  return "snapshot";
};

export const DialogResponses: React.FC<DialogResponsesProps> = ({
  generalResponses,
  categoryResponses,
  onResponse,
  showOptions,
  elapsedTime,
  emrUpdate,
  setEmrUpdate,
  emrInteractive,
  lastEmrUpdate,
  toggleTranscript,
  showResponses,
}) => {
  return (
    <>
      <ResponseLabel>You say...</ResponseLabel>

      <ScrollIntoView showContents={showResponses}>
        {generalResponses.map((response, idx) => (
          <ResponseBox key={idx} onClick={() => onResponse(response, idx)}>
            {response.txt}
          </ResponseBox>
        ))}

        <CategoryResponsesPrompt
          responses={categoryResponses}
          onResponse={onResponse}
        />
      </ScrollIntoView>

      {showOptions && (
        <BottomBar>
          <BottomBarRow>
            <DialogTimeElapsed label={`${elapsedTime} Minutes`} />
            <InsightDisplay />
          </BottomBarRow>
          <BannerContainer>
            {emrUpdate && (
              <EMRBanner
                caption={emrUpdate}
                onClose={() => setEmrUpdate(null)}
              />
            )}
          </BannerContainer>
          <BottomBarRow>
            <DialogMenuButton />
            <DialogPatientChart
              enabled={emrInteractive}
              onClick={() => {
                if (!emrInteractive) return;

                window.showEMR(getEmrTab(lastEmrUpdate), null, true);
                setEmrUpdate(null);
              }}
            />
            <TranscriptButton onClick={toggleTranscript} />
          </BottomBarRow>
        </BottomBar>
      )}
    </>
  );
};

const ScrollIntoView = styled.div<{ showContents: boolean }>`
  label: ScrollIntoView;
  overflow-y: auto;
  position: relative;
  left: ${({ showContents }) => (showContents ? "0px" : "-500px")};
  opacity: ${({ showContents }) => (showContents ? 1 : 0)};
  transition: ${({ showContents }) =>
    showContents ? "left 0.5s ease-in-out, opacity 0.5s ease-in-out" : "none"};
  align-self: stretch;
`;
