import { DialogLoader } from "../dialog/DialogLoader";
import { GameStage } from "../state/gameStateAtom";
import GameCompleteScreen from "./GameCompleteDialog";

export const gameStages: GameStage[] = [
  {
    id: "core1",
    label: "Introduction",
    steps: [
      {
        id: "core1-step1",
        label: "CMO Introduction",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="cmo_001_revise"
            sheetId="cmo_001_revise"
            idleSrc="/dialog_videos/cmo/idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
    ],
  },
  {
    id: "core2",
    label: "Asking what matters",
    steps: [
      {
        id: "core2-step1",
        label: "Mger Business as Usual",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="mger_bau"
            sheetId="mger_bau"
            idleSrc="/dialog_videos/geron/geron_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step2",
        label: "Donna Business as Usual",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="donna_bau"
            sheetId="donna_bau"
            showPromptButtons={true}
            idleSrc="/dialog_videos/donna/donna_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step3",
        label: "Mger 002",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="mger_002"
            sheetId="mger_002"
            idleSrc="/dialog_videos/geron/geron_idle.mp4"
            onComplete={onComplete}
            initialContext={dialogContext}
            show4MEmrTab={true}
          />
        ),
      },
      {
        id: "core2-step4",
        label: "Donna 4M Welless",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="donna_4m_wellness"
            sheetId="donna_4m_wellness"
            showPromptButtons={true}
            show4MEmrTab={true}
            idleSrc="/dialog_videos/donna/donna_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step5",
        label: "Mger 003",
        component: (onComplete, dialogContext) => (
          <DialogLoader
            key="mger_003"
            sheetId="mger_003"
            idleSrc="/dialog_videos/geron/geron_idle.mp4"
            initialContext={dialogContext}
            onComplete={onComplete}
          />
        ),
      },
      {
        id: "core2-step6",
        label: "Game Complete",
        component: (onComplete) => (
          <GameCompleteScreen onComplete={onComplete} />
        ),
      },
    ],
  },
];
